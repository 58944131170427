import React from 'react';

import Book from '../../images/Book';
import Compass from '../../images/Compass';
import Search from '../../images/Search';

import style from './FinancialToolsOfferWall.scss';

import cn from 'classnames';

export interface IFinancialToolsOfferWallProps {
  className?: string;
  items: {
    title: string;
    link: string;
  }[];
}

export const FinancialToolsOfferWall = (props: IFinancialToolsOfferWallProps) => {
  return (
    <div className={cn(style.financialToolsOfferWall, props.className)}>
      <div className="container">
        <div className={style.links}>
          {props.items?.map((item, index) => (
            <a href={item.link} className={style.base} key={item.title + index}>
              <div>
                {index === 0 && <Book width={36} height={36} />}
                {index === 1 && <Search width={36} height={36} />}
                {index === 2 && <Compass width={36} height={36} />}
              </div>
              <h4>{item.title}</h4>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};
