import React from 'react';

interface IIconProps {
  width?: number;
  height?: number;
  className?: string;
}

const CompassIcon = (props: IIconProps) => {
  const { width = 24, height = 24, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0034 11.5924C10.1872 11.1882 10.2791 10.986 10.4084 10.813C10.5232 10.6594 10.6594 10.5232 10.813 10.4084C10.986 10.2791 11.1882 10.1872 11.5924 10.0034L16 8L13.9966 12.4076C13.8128 12.8118 13.7209 13.014 13.5916 13.187C13.4768 13.3406 13.3406 13.4768 13.187 13.5916C13.014 13.7209 12.8118 13.8128 12.4076 13.9966L8 16L10.0034 11.5924Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="1.5" />
    </svg>
  );
};

export default React.memo(CompassIcon);
