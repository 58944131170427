import React from 'react';

interface IIconProps {
  rotate?: number;
}

const ArrowIcon = (props: IIconProps) => {
  const { rotate = 0 } = props;

  return (
    <svg
      width="46"
      height="24"
      viewBox="0 0 46 24"
      style={{ transform: `rotate(${rotate}deg)` }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 .686L1.686 12h0L13 23.314M2 12h47"
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default React.memo(ArrowIcon);
