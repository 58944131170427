import React from 'react';

import { Layout } from '../components/common';
import { MetaData } from '../components/common/meta';
import { FinancialToolsOfferWall } from '../components/FinancialToolsOfferWall/FinancialToolsOfferWall';
import { Hero } from '../components/InnerPages/Hero';
import LoansIcon from '../images/Loans';

import style from '../styles/pages/extra-money.scss';

import { Helmet } from 'react-helmet';

interface ILoansProps {
  location: any;
}

const Loans = ({ location }: ILoansProps) => {
  return (
    <>
      <Helmet>
        <script type="text/javascript">{`
       var _clcktt = _clcktt || {};
        _clcktt['cIdDesktop'] = "7351";
        _clcktt['cIdMobile'] = "56269";
        (function() {
            var ca = document.createElement('script'); ca.type = 'text/javascript';
            ca.async = true;
            ca.src = '//revpie.com/api/js/extractor.js';
            var sc = document.getElementsByTagName('script')[0]; sc.parentNode.insertBefore(ca, sc);
        })();
      `}</script>
        <style>{`
        .container {
          background-color: initial !important;
        }

        main > div {
          background-color: #fff;
        }
      `}</style>
      </Helmet>
      <MetaData data={{}} location={location} title="Simple & Fast Personal Loans" />
      <Layout headerClassName={style.header} mainClass={style.main}>
        <Hero
          className={style.hero}
          icon={<LoansIcon />}
          title="Simple & Fast Personal Loans"
          description="For any credit score"
        />
        <div className={style.content}>
          <div className="container">
            <div id="clcktt"></div>
            <FinancialToolsOfferWall
              items={[
                {
                  title: 'Best Personal Loans For 2025',
                  link: '/best-payday-loans-online/',
                },
                {
                  title: 'All Personal Loans Reviews',
                  link: '/tag/payday-loans/',
                },
                {
                  title: 'Download True FInance for more',
                  link: 'https://truefinance.go.link/5WZU2',
                },
              ]}
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Loans;
